import { FC, useEffect, useState } from "react";

type TimerType = {
  date?: Date;
  className?: string;
  onTimedout?: () => void;
};

const TimerComponent: FC<TimerType> = ({ date, className, onTimedout }) => {
  const calculateTimeLeft = () => {
    const difference: number = +new Date(date || new Date()) - +new Date();
    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    const difference: number = +new Date(date || new Date()) - +new Date();

    if (difference <= 0 && !hasTimedOut) {
      setHasTimedOut(true);
      if (typeof onTimedout === "function") {
        onTimedout();
      }
    }
  }, [date, onTimedout, hasTimedOut]);

  useEffect(() => {
    const timer: number = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="flex items-center">
      <span className={`font-mono countdown ${className}`}>
        <span style={{ "--value": timeLeft.hours }}>
          {" "}
          {timeLeft.hours.toString().padStart(2, "0")}
        </span>
        :
        <span style={{ "--value": timeLeft.minutes }}>
          {timeLeft.minutes.toString().padStart(2, "0")}
        </span>
        :
        <span style={{ "--value": timeLeft.seconds }}>
          {timeLeft.seconds.toString().padStart(2, "0")}
        </span>
      </span>
    </div>
  );
};

export default TimerComponent;
