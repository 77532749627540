import { FC, Fragment, MouseEvent, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { toShort2 } from "../../utils/common.util.ts";
import { useCopy } from "../../hooks/copy.hook.ts";
import { Transition } from "@headlessui/react";
import { Position } from "../../enums/position.enum.ts";

type AddressComponentType = {
  address: string;
  showCopy?: boolean;
  shortFirst?: number;
  shortLast?: number;
  disabledSep?: boolean;
  showCopyMessageTimeout?: number;
  position?: Position;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

const AddressComponent: FC<AddressComponentType> = ({
  address,
  showCopy,
  shortFirst,
  shortLast,
  disabledSep,
  showCopyMessageTimeout,
  position,
  onClick,
}) => {
  const [isCopied, copyApiKey] = useCopy(showCopyMessageTimeout);

  const positionOuterClasses = useMemo(() => {
    switch (position) {
      case Position.RIGHT:
        return "left-full top-1/2 transform -translate-y-1/2";
      case Position.LEFT:
        return "right-full top-1/2 transform -translate-y-1/2";
      case Position.BOTTOM:
        return "top-full left-1/2 transform -translate-x-1/2";
      default:
        return "bottom-full left-1/2 transform -translate-x-1/2";
    }
  }, [position]);

  const copy = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      await copyApiKey(address);
    },
    [address, copyApiKey],
  );

  return (
    <div className="flex items-center justify-between">
      <div
        className="cursor-pointer font-medium text-indigo-500 hover:underline"
        onClick={onClick}
      >
        {toShort2(address, shortFirst, shortLast, disabledSep)}
      </div>
      <div className="relative">
        <div className={`z-50 absolute ${positionOuterClasses}`}>
          <Transition
            as={Fragment}
            show={isCopied}
            enter="transition ease-in-out duration-200"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            <div className="rounded overflow-hidden bg-slate-800 border border-slate-200 shadow-lg w-max">
              <div className="font-medium text-slate-200 px-2 py-1 w-full text-center">
                Copied
              </div>
            </div>
          </Transition>
        </div>
        <div className="flex items-center justify-between">
          {showCopy && (
            <button className="ml-2" aria-label="copy" onClick={copy}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.99999 0.916626C2.44746 0.916626 1.91755 1.13612 1.52685 1.52682C1.13615 1.91752 0.916656 2.44743 0.916656 2.99996V8.33329C0.916656 8.88583 1.13615 9.41573 1.52685 9.80643C1.91755 10.1971 2.44746 10.4166 2.99999 10.4166H3.58332V11C3.58332 12.1506 4.51606 13.0833 5.66666 13.0833H11C12.1506 13.0833 13.0833 12.1506 13.0833 11V5.66663C13.0833 4.51603 12.1506 3.58329 11 3.58329H10.4167V2.99996C10.4167 2.44742 10.1972 1.91752 9.80646 1.52682C9.41576 1.13612 8.88586 0.916626 8.33332 0.916626H2.99999ZM8.91666 3.58329V2.99996C8.91666 2.84525 8.8552 2.69688 8.7458 2.58748C8.63641 2.47808 8.48803 2.41663 8.33332 2.41663H2.99999C2.84528 2.41663 2.69691 2.47808 2.58751 2.58748C2.47811 2.69688 2.41666 2.84525 2.41666 2.99996V8.33329C2.41666 8.488 2.47811 8.63638 2.58751 8.74577C2.69691 8.85517 2.84528 8.91663 2.99999 8.91663H3.58332V5.66663C3.58332 4.51603 4.51606 3.58329 5.66666 3.58329H8.91666ZM5.08332 9.66663V11C5.08332 11.3221 5.34449 11.5833 5.66666 11.5833H11C11.3222 11.5833 11.5833 11.3221 11.5833 11V5.66663C11.5833 5.34446 11.3222 5.08329 11 5.08329H9.66666H5.66666C5.34449 5.08329 5.08332 5.34446 5.08332 5.66663V9.66663Z"
                  fill="#6366F1"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AddressComponent.propTypes = {
  address: PropTypes.string.isRequired,
  showCopy: PropTypes.bool,
  shortFirst: PropTypes.number,
  shortLast: PropTypes.number,
  disabledSep: PropTypes.bool,
  showCopyMessageTimeout: PropTypes.number,
  position: PropTypes.oneOf<Position>(Object.values(Position) as Position[]),
  onClick: PropTypes.func,
};

AddressComponent.defaultProps = {
  showCopy: true,
  shortFirst: 6,
  shortLast: 6,
  disabledSep: false,
  showCopyMessageTimeout: 3000,
  position: Position.RIGHT,
  onClick: () => console.log("click"),
};

export default AddressComponent;
